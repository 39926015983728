import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy and Contact" />
    <div className="max-w-3xl pt-6 md:pt-12 mx-auto relative z-10">
      <div className="max-w-xl mx-4">
        <h1 className="mb-8 font-semibold text-black">
          Imprint
        </h1>
        <p className="text-lg mb-8 leading-snug text-gray-800">
          Audyem GmbH<br/>
          Ottersbekallee 6<br/>
          20255 Hamburg<br/>
        </p>

        <p className="text-lg mb-8 leading-snug text-gray-800">
        Amtsgericht Hamburg<br/>
        HRB 149784 / USt. Nr. 49/751/01172<br/>
        Geschäftsführung: Paul Solbach<br/><br/>
        hi@audyem.com
        </p>
      </div>
    </div>

    <div className="max-w-100 w-screen absolute audyem-bg-wave -mt-32 pt-32 md:pt-40 pb-24"></div>
    
    <div className="w-full mt-24 pt-2 bg-white pb-4">
      <div className="max-w-3xl mx-auto relative z-10 mt-8 mb-0">
        <div className="max-w-xl mx-4">
          <p className="text-base mb-8 leading-snug text-gray-600">
            <span className="pb-1 block text-gray-600">1. Haftung für Inhalte</span>
            Alle Inhalte unseres Internetauftritts wurden mit größter Sorgfalt und nach bestem Gewissen erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntniserlangung einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von den o.g. Rechtsverletzungen werden wir diese Inhalte unverzüglich entfernen.
          </p>

          <p className="text-base mb-8 leading-snug text-gray-600">
            <span className="pb-1 block text-gray-600">2. Haftung für Links</span>
            Unsere Webseite enthält Links auf externe Webseiten Dritter. Auf die Inhalte dieser direkt oder indirekt verlinkten Webseiten haben wir keinen Einfluss. Daher können wir für die „externen Links“ auch keine Gewähr auf Richtigkeit der Inhalte übernehmen. Für die Inhalte der externen Links sind die jeweilige Anbieter oder Betreiber (Urheber) der Seiten verantwortlich. Die externen Links wurden zum Zeitpunkt der Linksetzung auf eventuelle Rechtsverstöße überprüft und waren im Zeitpunkt der Linksetzung frei von rechtswidrigen Inhalten. Eine ständige inhaltliche Überprüfung der externen Links ist ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei direkten oder indirekten Verlinkungen auf die Webseiten Dritter, die außerhalb unseres Verantwortungsbereichs liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall nur bestehen, wenn wir von den Inhalten Kenntnis erlangen und es uns technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Diese Haftungsausschlusserklärung gilt auch innerhalb des eigenen Internetauftrittes gesetzten Links und Verweise von Fragestellern, Blogeinträgern, Gästen des Diskussionsforums. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargestellten Informationen entstehen, haftet allein der Diensteanbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Werden uns Rechtsverletzungen bekannt, werden die externen Links durch uns unverzüglich entfernt.
          </p>

          <p className="text-base mb-8 leading-snug text-gray-600">
            <span className="pb-1 block text-gray-600">3. Urheberrecht</span>
            Die auf diesem Internetangebot enthaltenen Inhalte sind, soweit möglich, urheberrechtlich geschützt. Es bedarf einer schriftlichen Genehmigung des Erstellers für denjenigen, der die Inhalte vervielfältigt, bearbeitet, verbreitet oder nützt. 
            Das Herunterladen und Kopieren dieser Internetangebot ist sowohl für den privaten als auch den kommerziellen Gebrauch durch uns schriftlich zu gestatten. Wir weisen darauf hin, dass hinsichtlich der Inhalte auf diesem Angebot, soweit sie nicht von uns erstellt wurden, das Urheberrecht von Dritten jederzeit beachtet wird. Wir verwenden in der Darstellung journalistischer Fremdinhalte keine über die allgemein verfügbaren RSS-Feeds hinausgehenden Volltexte. 
            Wenn Sie eine Urheberrechtsverletzung gefunden haben, teilen Sie uns dies gerne mit. Wir werden den entsprechenden Inhalt sofort entfernen und damit das Urheberrecht nicht mehr verletzen.
          </p>

          <p className="text-base leading-snug text-gray-600">
            <span className="pb-1 block text-gray-600">4. Datenschutz</span>
            Dieses Internetangebot kann regelmässig ohne die Angabe von personenbezogenen Daten genutzt werden. Falls solche Daten (z.B. Name, Adresse oder E-Mail) doch erhoben werden sollten, geschieht dies freiwillig oder nur mit ausdrücklicher Zustimmung durch unsere Nutzer. 
            Die Übertragung von Daten im Internet ist nie restlos sicher. Es ist daher möglich, dass Dritte Zugriff auch auf unsere Daten erlangen. Ein lückenloser Schutz ist nicht möglich. Wir verschlüsseln Verbindungen zu diesem Internetangebot mit TLS via Mozilla Let's Encrypt. 
            Wir widersprechen an dieser Stelle der Nutzung unserer Kontaktdaten für die Zustellung nicht verlangter Werbung / Informationsmaterial / Spam-Mails. Sollte dies dennoch geschehen, werden wir rechtliche Schritte einleiten.
          </p>

        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
